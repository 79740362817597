import { REDEEM_LOADING, SET_REDEEM, GET_REDEEM } from '../types';

const initialState = {
  redeem: null,
  redeemed: null,
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case REDEEM_LOADING:
    return {
      ...state,
      loading: true,
    };
    case  GET_REDEEM:
      return {
        ...state,
        redeemed: action.payload,
        loading: false,
      };
    case SET_REDEEM:
      return {
        ...state,
        redeem: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

