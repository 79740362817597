import axios from 'axios';

const host = window.location.host;
let dev = false;

let h = host.split(":")
console.log(h, "h")
if (h[1] &&  h[0] == "localhost") dev = true

console.log(document.location.host)

let api_url = dev ? `http://localhost:5000` : `https://movies-apis.nairabox.net`;


const connect = axios.create({baseURL: api_url, timeout: 200000,
    //headers: {'Authorization': 'Token fdbc9d13a911165afc9fad74f9b0be3a51d787c9'}
  });

  export default connect