import axios from 'axios';

const host = window.location.host;
let dev = false;
let h = host.split(":")
console.log("h", h)
if (h[1] && (h[0] == "localhost")) dev = true

export const MOVIE_DASHBOARD_API_LOCAL = dev ? `http://localhost:5000` : `https://movies-apis.nairabox.net`

axios.defaults.baseURL = MOVIE_DASHBOARD_API_LOCAL;
axios.interceptors.request.use((config) => {
    config.baseURL = MOVIE_DASHBOARD_API_LOCAL;
    const token = window.localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})
export const Axios = axios