// ERRORS
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// USERS AUTH
// ERRORS
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
// MOVIES...
export const GET_MOVIES = 'GET_MOVIES';
export const GET_MOVIES_LOADING = 'GET_MOVIES_LOADING';

// MOVIES LINKING...
export const GET_FH_MOVIES_SUCCESS = 'GET_FH_MOVIES_SUCCESS';
export const GET_FH_MOVIES_PENDING = 'GET_FH_MOVIES_PENDING';
export const GET_FH_MOVIES_FAILED = 'GET_FH_MOVIES_FAILED';

export const GET_NB_MOVIES_SUCCESS = 'GET_NB_MOVIES_SUCCESS';
export const GET_NB_MOVIES_PENDING = 'GET_NB_MOVIES_PENDING';
export const GET_NB_MOVIES_FAILED = 'GET_NB_MOVIES_FAILED';

export const POST_NB_GENERAL_FAILED = 'POST_NB_GENERAL_FAILED';
export const POST_NB_GENERAL_PENDING = 'POST_NB_GENERAL_PENDING';
export const POST_NB_GENERAL_SUCCESS = 'POST_NB_GENERAL_SUCCESS';

//LOCATIONS...
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_PENDING = 'GET_LOCATIONS_PENDING';
export const GET_LOCATIONS_FAILED = 'GET_LOCATIONS_FAILED';

// MOVVIES TICKET...
export const GET_MOVIE_TICKET = 'GET_MOVIE_TICKET';
export const GET_MOVIES_TICKET = 'GET_MOVIES_TICKET';
export const MOVIES_TICKET_LOADING = 'MOVIES_T_LOADING';

// SHOWTIMES...
export const GET_SHOWTIMES = 'GET_SHOWTIMES';
export const GET_SHOWTIME = 'GET_SHOWTIME';
export const SHOWTIME_LOADING = 'SHOWTIME_LOADING';

// SHOWTIMES FOR CINEMA LINKING...
export const GET_SHOWTIMES_SUCCESS = 'GET_SHOWTIMES_SUCCESS';
export const GET_SHOWTIMES_PENDING = 'GET_SHOWTIMES_PENDING';
export const GET_SHOWTIMES_FAILED = 'GET_SHOWTIMES_FAILED';

//LOADING ERROR_SET, ERROR_CLEAR
export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';

//ERROR
export const ERROR_SET = 'ERROR_SET';
export const ERROR_CLEAR = 'ERROR_CLEAR';

// GIFTING...
export const GIFT_LOADING = 'GIFT_LOADING';
export const GET_GIFTS = 'GET_GIFTS';
export const GET_GIFT = 'GET_GIFT';
export const SET_GIFT = 'SET_GIFT';


// WALLET...
export const GET_WALLETS = 'GET_WALLETS';
export const GET_WALLET = 'GET_WALLET';
export const WALLET_LOADING = ' WALLET_LOADING';
export const SET_WALLET = 'SET_WALLET';




// MERCHANTS...
export const MERCHANT_LOADING = 'MERCHANT_LOADING';
export const GET_MERCHANT = 'GET_MERCHANT';
export const GET_MERCHANTS = 'GET_MERCHANTS';

// TRANSACTIONS...
export const TRANSACTION_LOADING = 'TRANSACTION_LOADING';
export const GET_DAILY_TRANSACTION = 'GET_DAILY_TRANSACTION';
export const GET_WEEKLY_TRANSACTION = 'GET_WEEKLY_TRANSACTION';
export const GET_MONTHLY_TRANSACTION = 'GET_MONTHLY_TRANSACTION';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const GET_RANGE = 'GET_RANGE';

// CINEMAS
export const CINEMA_LOADING = 'CINEMA_LOADING';
export const GET_CINEMAS = 'GET_CINEMAS';

// REPORTS...

// REDEMPTIONS...
export const REDEEM_LOADING = 'REDEEM_LOADING';
export const SET_REDEEM = 'SET_REDEEM';
export const GET_REDEEM = 'GET_REDEEM';


// DYNAMIC IMAGES...

//
