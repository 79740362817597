import Page from 'components/Page';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Table, Row } from 'reactstrap';
import TicketItem from '../../components/common/List/MovieItem';
// import Spinner from '../../components/common/spinner';
// import spinner_image from '../../utils/spin.gif';
import { getMoviesTickets } from '../../redux/actions/movies';

class ListMovies extends Component {
  componentDidMount() {
    const { getMoviesTickets } = this.props;
    getMoviesTickets();
  }

  render() {
    const {mt: {loading, movieTickets}} = this.props;

    let list_movieTicket_items;

    list_movieTicket_items = !movieTickets ? (<h4>No Movie Tickets Where Found!</h4>) :
        (movieTickets.map((mt, index) => (
          <TicketItem key={mt._id} index={index} ticketDetails={mt} history={this.props.history}/>
        )));

    return (
      <Page title="List Movies" breadcrumbs={[{ name: 'List Movies', active: true }]}>
        <Row>
          <Col xl={12} lg={12} md={12}>
            <Card>
              <CardHeader>List all movies</CardHeader>
              <CardBody>
                <Col>
                  <Card body>
                    <Table striped={true} id="listmoviestable" >
                      {/* HEAD */}
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Movie Title</th>
                          <th>DURATION</th>
                          <th>GENRE</th>
                          <th>AVAILABLE</th>
                          <th>Showtime</th>
                        </tr>
                      </thead>

                      {/* DYNAMIC BODY */}
                      {list_movieTicket_items}
                      {/* TRACK */}
                    </Table>
                  </Card>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

const map_state_to_props = state => ({
  mt: state.movieTickets,
});

export default connect(
  map_state_to_props,
  { getMoviesTickets },
)(withRouter(ListMovies));
