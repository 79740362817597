import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

// import classnames from 'classnames';
// import PropTypes from 'prop-types';

export default class RedeemedList extends Component {
  render() {
    const {
      redeem: { _id, email, ticketid, price },
      index,
      toggle,
      history,
    } = this.props;

    return (
      <React.Fragment>
        <tbody>
          <tr>
            <th scope="row">{index + 1}</th>
            <td>{email}</td>
            <td>{ticketid}</td>
            <td>{price}</td>
          </tr>
        </tbody>
      </React.Fragment>
    );
  }
}
