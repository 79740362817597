import axios from 'axios';
import { MOVIE_DASHBOARD_API_LOCAL } from '../axios.request.link';
import {
  WALLET_LOADING,
  GET_WALLET,
  GET_WALLETS,
  SET_WALLET,
  GET_ERRORS,
} from '../types';

export const setWalletLoading = () => {
  return {
    type: WALLET_LOADING,
  };
};

export const getWallets = _ => async dispatch => {
  dispatch(setWalletLoading());
  // MAKME REQUEST
  axios
    .get(`${MOVIE_DASHBOARD_API_LOCAL}/vouchers/wallets`)
    .then(({ data }) =>
      dispatch({
        type: GET_WALLETS,
        payload: data.wallets,
      }),
    )
    .catch(error =>
      dispatch({
        type: GET_WALLETS,
        payload: null,
      }),
    );
};

export const getWallet = id => async dispatch => {
  dispatch(setWalletLoading());
  // MAKME REQUEST
  axios
    .get(`${MOVIE_DASHBOARD_API_LOCAL}/vouchers/wallets?_id=${id}`)
    .then(({ data }) =>
      dispatch({
        type: GET_WALLET,
        payload: data.wallet,
      }),
    )
    .catch(error =>
      dispatch({
        type: GET_WALLET,
        payload: null,
      }),
    );
};

export const setWallet = data => dispatch => {
  dispatch(setWalletLoading());
  dispatch({
    type: SET_WALLET,
    payload: data,
  });
};

export const addWallet = (data, history) => async dispatch => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  axios
    .post(`${MOVIE_DASHBOARD_API_LOCAL}/vouchers/assign`, data, config)
    .then(result => history.push('/list-wallets'))
    .catch(error =>
      error.response
        ? dispatch({
            type: GET_ERRORS,
            payload: error.response.data,
          })
        : dispatch({
            type: GET_ERRORS,
            payload: {
              path: 'no response',
              mssg: 'couldnt reach the server please try again later',
            },
          }),
    );
};

export const topUp = (_id, data, history) => async dispatch => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  axios
    .post(`${MOVIE_DASHBOARD_API_LOCAL}/vouchers/topup`, data, config)
    .then(result => {
      console.log(result);
      history.push('/list-wallets');
    })
    .catch(error =>
      error.response
        ? dispatch({
            type: GET_ERRORS,
            payload: error.response.data,
          })
        : dispatch({
            type: GET_ERRORS,
            payload: {
              path: 'no response',
              mssg: 'couldnt reach the server please try again later',
            },
          }),
    );
};
