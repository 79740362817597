import Page from 'components/Page';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { topUp } from '../../redux/actions/vouchers';
import moment from 'moment';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { topUpState } from '../../initializers';



class TopUp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...topUpState,
    };
  }


  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { topUp, history } = this.props;

    const newStateObject = {
      ...this.state,
    };
   

    
    topUp(newStateObject, history);
  };


  render() {
    return (
      <Page
        title="Account"
        breadcrumbs={[{ name: 'Credit Account', active: true }]}
      >
        <Row>
          <Col xl={12} lg={12} md={12}>
            <Card>
              <CardHeader>Credit Account</CardHeader>
              <CardBody>
                <Form>
                   {/* MERCHANT NAME */}
                   <FormGroup row>
                    <Label for="email" sm={3}>
                      Merchant Email
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Enter Merchant Email"
                        onChange={this.onChange}
                      />
                    </Col>
                  </FormGroup>
                  
                  {/* MERCHANT EMAIL */}
                  <FormGroup row>
                    <Label for="amount" sm={3}>
                      Enter Amount
                    </Label>
                    <Col sm={6}>
                    <Input
                        type="number"
                        name="amount"
                        placeholder="Enter Amount"
                        onChange={this.onChange}
                      />
                    </Col>
                  </FormGroup>
                  {/* BUTTON */}
                  <FormGroup check row>
                    <Col sm={{ size: 9, offset: 3 }}>
                      <Button onClick={this.onSubmit}>CREDIT ACCOUNT</Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

const map_state_to_props = state => ({
  gift: state.gift,
  mt: state.movieTickets,
  cinema: state.cinemas,
});

export default connect(
  map_state_to_props,
  {  topUp },
)(withRouter(TopUp));


