import { MERCHANT_LOADING, GET_MERCHANT, GET_MERCHANTS } from '../types';

const initialState = {
  merchants: [],
  next: 0,
  page: 0,
  merchant: null,
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MERCHANT_LOADING:
      return {...state, loading: true};
    case GET_MERCHANTS:
      return {...state, merchants: action.payload ? action.payload.data : [], next: action.payload ? action.payload.next : 0, page: action.payload ? action.payload.page : 0, loading: false};
    case GET_MERCHANT:
      return {...state, merchant: action.payload, loading: false};
    default:
      return state;
  }
}
