import Page from 'components/Page';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Badge, Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row} from 'reactstrap';

//import Axios from '../Axios';

import {Axios} from '../redux/axios.request.link';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFilmhouseMovies } from '../redux/actions/movies-linking.js';
import { getNairaboxMovies, postFilmhouseIdTicketId } from '../redux/actions/nairaboxMovies';
import Select from "react-dropdown-select";

class CinemaLinking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cinema: "",
      cinemas: {},
      cinemas2: [],
      nairaboxMovies: [],
      nairaboxMoviesFull: [],

      filteredCinemaMovies: [],
      selectedCinemaMovie: "",

      filteredNairaboxMovies: [],
      selectedNairaboxMovie: "",

      buttonText: "Submit",
      buttonText2: "Update",

    }
  }

  async componentDidMount() {

    try {
      let res = await Axios.get("/api/get-jackrow-cinemas")
        console.log("hhhhh", res)
      let updated = {}
      let updated2 = []
      for (let index = 0; index < res.data.length; index++) {
        const ele = res.data[index];
        updated[ele.id] = {id: ele.id, name: ele.name, movies: {}}
        updated2.push({label: ele.name, value: ele.id})
        for (let i = 0; i < ele.movies.length; i++) {
          const elem = ele.movies[i];
          updated[ele.id].movies[elem.id] = elem
        }
      }
      this.setState({cinemas: updated})
      this.setState({cinemas2: updated2})

      let res2 = await Axios.get("/api/get-nairabox-movies")
      this.setState({nairaboxMovies: res2.data})
      
      let res3 = await Axios.get("/api/get-nairabox-movies?all=true")
      let re = []
      for (let i = 0; i < res3.data.length; i++) {
        const ele = res3.data[i];
        re.push({label: ele.name, value: ele._id})
      }
      //console.log(res3.data, re)
      this.setState({filteredNairaboxMovies: re})
    } catch (error) {
      console.log(error.response ? error.response.data : error.message )
    }
  }

  onCinemaHouseSelect = (e) => {
    this.setState({ cinema: e.target.value });
    console.log('selected: ', e.target.value)
    this.setState({ searchString: '' });
    this.setState({ filtered: [] });
  }

  onLinkCinemas = async () => {
    if(!this.state.cinema){ alert("You have to select the cinema"); return} 
    if(!this.state.selectedCinemaMovie){ alert("You have to select the cinema Movie"); return}
    if(!this.state.selectedNairaboxMovie){ alert("You have to select the Nairabox movie to Link"); return}
    try {
      this.setState({buttonText: "Loading..."})
      let res = await Axios.post("/api/jackrow/link-movies", {siteID: this.state.cinema, movieID: this.state.selectedCinemaMovie, mongoID: this.state.selectedNairaboxMovie})
      this.setState({selectedCinemaMovie: "", selectedNairaboxMovie: ""})
      let res2 = await Axios.get("/api/get-nairabox-movies")
      this.setState({nairaboxMovies: res2.data})
      this.setState({buttonText: "Submit"})
    } catch (error) {
        this.setState({buttonText: "Submit"})
      console.log(error.response ? error.response.data : error.message )
      alert(error.response ? error.response.data : error.message)
    }
  }

  updateDB = async () => {
    try {
      this.setState({buttonText2: "Loading..."})
      await Axios.get("/api/update/alll/db")
      this.setState({buttonText2: "Update"})
    } catch (error) {
        this.setState({buttonText2: "Update"})
      console.log(error.response ? error.response.data : error.message )
      alert(error.response ? error.response.data : error.message)
    }
  }

  onSearchString = (e) => {
    let { value } = e.target;
    if(this.state.cinema){
      let movies = Object.keys(this.state.cinemas[this.state.cinema].movies)
      let filteredDown = movies.filter((i, index, arr) => {
        let item = this.state.cinemas[this.state.cinema].movies[i]
        return item.title.toLowerCase().indexOf(value.toLowerCase()) !== -1
      })
      this.setState({ filteredCinemaMovies: filteredDown });
      if(filteredDown.length > 0){ 
        this.setState({ selectedCinemaMovie: filteredDown[0]});
      }else{
        this.setState({ selectedCinemaMovie: ""});
      }
      console.log(value, movies.length, filteredDown.length, this.state.selectedCinemaMovie)
  }
  }

  onSearchStringNairabox = (e) => {
    let { value } = e.target;
    if(this.state.cinema){
      let filteredDown = this.state.nairaboxMoviesFull.filter((item) => {
        return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      })
      this.setState({ filteredNairaboxMovies: filteredDown });
      if(filteredDown.length > 0) this.setState({ selectedNairaboxMovie: filteredDown[0]._id });
      console.log(value, this.state.nairaboxMoviesFull.length, filteredDown.length, this.state.selectedNairaboxMovie)
  }else{
      console.log("wrong")
  }
  }

  render() {

    let { cinemaHouse, searchString, selectedNairaboxMovie, filteredCinemaMovies, filteredNairaboxMovies} = this.state;
    let { nairaboxMovies, nairaboxMoviesReducer } = this.props;

    let nairaboxMoviesOptions = nairaboxMovies[0] && nairaboxMovies.map((item, ind) => {
      return <option value={item.id} key={item.id + '-' + ind}>{item.title}</option>
    })

    let ccs = Object.keys(this.state.cinemas)
    return (
      <Page  title="Cinema Linking Jackrow" breadcrumbs={[{ name: 'Cinema Linking Jackrow', active: true }]}
      >
        <Row>

          <Col xl={12} lg={12} md={12}>
            <Card>
              <CardHeader>Cinema Linking Jackrow</CardHeader>
              <CardBody>
                <Form>

                  <FormGroup row>
                    <Label for="email" sm={3}> Select Cinema </Label>
                    <Col sm={6}>
                    <Select options={this.state.cinemas2} onChange={(e) => {
                          if (e.length == 0) {
                            return
                          }
                          this.setState({ cinema: e[0].value });
                          console.log('selected: ', e)
                          let cm = []
                          let j = Object.values(this.state.cinemas[e[0].value].movies)
                          for (let i = 0; i < j.length; i++) {
                            const ele = j[i];
                            cm.push({label: ele.title, value: ele.id})
                          }
                          this.setState({ filteredCinemaMovies: cm }); 
                          this.setState({ selectedCinemaMovie: '' });
                          this.setState({ selectedNairaboxMovie: "" });
                      }} />
                      {/*
                      <Input type="select" name="select-cinema" value={this.state.cinema} onChange={this.onCinemaHouseSelect}>
                      <option value="">Select cinema</option>
                        {ccs.map((el, key) => {
                            let ele = this.state.cinemas[el]
                            return <option key={key} value={ele.id}>{ele.name ? ele.name : ele.id}</option>
                        })}
                      </Input>
                      */}

                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="email" sm={3}> Cinema Movie </Label>
                    <Col sm={6}>
                    <Select options={this.state.filteredCinemaMovies} onChange={(values) => {
                        this.setState({selectedCinemaMovie: values[0] ? values[0].value : "" })
                        console.log(values)
                         //this.onCinemaChainSelect(values)
                         }} />
                      {/*
                      <Input type="text" name="cinema-movie" onChange={this.onSearchString} list="theFiltered"/>
                      <datalist id="theFiltered">
                        {filteredCinemaMovies.map((it, index) => {
                          let item = this.state.cinemas[this.state.cinema].movies[it]
                          return <option key={index} value={item ? item.title : ""}></option>
                        })}
                      </datalist>
                        */}
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="email" sm={3}> Nairabox Movie </Label>
                    <Col sm={6}>
                    <Select options={this.state.filteredNairaboxMovies} onChange={(values) => {
                        this.setState({selectedNairaboxMovie: values[0] ? values[0].value : "" })
                        console.log(values)
                         //this.onCinemaChainSelect(values)
                         }} />
                      {/*
                      <Input type="text" name="nairabox-movie" onChange={this.onSearchStringNairabox} list="theFilteredNairabox"/>
                      <datalist id="theFilteredNairabox">
                        {filteredNairaboxMovies.map((item, index) => {
                          return <option key={index} value={item.name}></option>
                        })}
                      </datalist>
                        */}
                    </Col>
                  </FormGroup>

                  <FormGroup check row>
                    <Col sm={{ size: 9, offset: 3 }}>
                      <Button onClick={this.onLinkCinemas}>
                        {this.state.buttonText}
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>

        <Col xl={12} lg={12} md={12}>
            <Card  style={{marginTop: "10px"}}>
                <CardHeader>UPDATE DATABASE</CardHeader>
                <CardBody> 
                <Button onClick={this.updateDB}>
                        {this.state.buttonText2}
                      </Button>
                </CardBody>
            </Card>
        </Col>

          <Col xl={12} lg={12} md={12}>

                {this.state.nairaboxMovies.map((ele, key) => {
                  return (
                  <Card key={key} style={{marginTop: "10px"}}>
                    <CardHeader>{ele.name}</CardHeader>
                    <CardBody>
                      <Row>
                        {ele.jackrowIds.map((el, k) => {
                          let cs = el.split(".")
                          return (<Col key={k} xl={6} lg={6} md={6}>
                                    {this.state.cinemas[cs[0]].name}: <h5>{this.state.cinemas[cs[0]].movies[cs[1]] ? this.state.cinemas[cs[0]].movies[cs[1]].title : cs[1]}</h5>
                                  </Col>)
                        })}
                      </Row>
                    </CardBody>
                  </Card>
                  )
                })}

          </Col>
        </Row>

        {/* <CinemaLocations /> */}
      </Page>
    );
  }



};

function mapStateToProps(state) {
  //console.log('state.nairaboxMoviesReducer: ', JSON.stringify(state.nairaboxMoviesReducer, null, 4));
  let { filmHouseMoviesReducer, nairaboxMoviesReducer } = state;
  let { movies } = filmHouseMoviesReducer;
  return {
    filmhouseMovies: movies,
    nairaboxMovies: nairaboxMoviesReducer.movies,
    nairaboxMoviesReducer
  }
}
function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators({ getFilmhouseMovies, getNairaboxMovies, postFilmhouseIdTicketId }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CinemaLinking);
