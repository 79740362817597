import axios from 'axios';
import Swal from 'sweetalert2'
import { MOVIE_DASHBOARD_API_LOCAL, Axios } from '../axios.request.link';
import {GET_MOVIES, GET_MOVIES_TICKET, GET_MOVIE_TICKET, MOVIES_TICKET_LOADING, GET_ERRORS} from '../types';
import store from "../store"

export const setMovieTicketLoading = _ => {
  return {type: MOVIES_TICKET_LOADING};
}

export const getMoviesTickets = () => async dispatch => {
  dispatch(setMovieTicketLoading());
  // MAKME REQUEST
  Axios.get(`api/tickets`)
    .then(result => {
      console.log("returned...", result.data)  
       dispatch({ type: GET_MOVIES_TICKET, payload: result.data})

    })
       
    .catch(e => {
      console.log(e.message)
      dispatch({type: GET_MOVIES_TICKET, payload: null})
      Swal.fire({title: 'Error!', text: e.response ? e.response.data.message : e.message, icon: 'error', confirmButtonText: 'ok'})
    }
    );
};

export const updateMovieTicketsStatus = (_id, index, value) => async dispatch => {
  let s = store.getState()
  let movies = Object.create(s.movieTickets.movieTickets);
  movies[index].available  = value;
  dispatch({ type: GET_MOVIES_TICKET, payload: movies})
  Axios.patch(`api/tickets/${_id}/available/${value}`)
  .then(result => {
  }).catch(e => {
    console.log(e.message)
    movies[index].available  = value ? 0 : 1;
    dispatch({ type: GET_MOVIES_TICKET, payload: movies})
    Swal.fire({title: 'Error!', text: e.response ? e.response.data.message : e.message, icon: 'error', confirmButtonText: 'ok'})
  });  

};

export const getMovieTicket = id => async dispatch => {
  dispatch(setMovieTicketLoading());
  // MAKME REQUEST
  axios
    .get(`${MOVIE_DASHBOARD_API_LOCAL}/tickets?_id=${id}`)
    .then(({ data }) =>
      dispatch({
        type: GET_MOVIE_TICKET,
        payload: data,
      }),
    )
    .catch(error =>
      dispatch({
        type: GET_MOVIE_TICKET,
        payload: null,
      }),
    );
};




export const addMovieTicket = (data, history) => async dispatch => {
  const config = {
    headers: {'content-type': 'multipart/form-data'}
  };

  axios.post(`${MOVIE_DASHBOARD_API_LOCAL}/tickets/new`, data, config)
    .then(result => {
      console.log(result.data);
      history.push('/dashboard');
    })
    .catch(error =>
      error.response
        ? dispatch({
            type: GET_ERRORS,
            payload: error.response.data,
          })
        : dispatch({
            type: GET_ERRORS,
            payload: {
              path: 'no response',
              mssg: 'Aunty Seyi we couldnt reach the server please try again later',
            },
          }),
    );
};
