import { WALLET_LOADING, GET_WALLET, GET_WALLETS } from '../types';

const initialState = {
  wallets: null,
  wallet: null,
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case WALLET_LOADING:
    return {
      ...state,
      loading: true,
    };
    case GET_WALLETS:
      return {
        ...state,
        wallets: action.payload,
        loading: false,
      };
    case GET_WALLET:
      return {
        ...state,
        wallet: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
