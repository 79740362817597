import axios from 'axios';
import { MOVIE_DASHBOARD_API_LOCAL, Axios } from '../axios.request.link';
import { MERCHANT_LOADING, GET_MERCHANT, GET_MERCHANTS, GET_ERRORS} from '../types';
import store from "../store"

export const setMerchantLoading = () => {
  return {
    type: MERCHANT_LOADING,
  };
};

export const getMerchants = (search, id, page, from, to) => async dispatch => {
  dispatch(setMerchantLoading());

  if (search == undefined){
    search = ""
  }
  if (page == undefined){
    page = ""
  }
  if (from == undefined){
    from = ""
  }
  if (to == undefined){
    to = ""
  }
  let add = `?search=${search}&page=${page}&from=${from}&to=${to}`
   
  Axios.post(`/api/thirdparty/tickets${add}`)
    .then(({ data }) => {
      console.log(data, "  ----  ", add)
      dispatch({type: GET_MERCHANTS, payload: data})
      if(id){
        getMerchant(id)
      }
    })
    .catch(error => {
      console.log("Did not get data....... ", error.response ? error.response.data : error.message )
      dispatch({type: GET_MERCHANTS, payload: null})
    });
};

export const getMerchant = id => async dispatch => {
  // MAKME REQUEST
  let state = store.getState()
  let done = false
  for (let index = 0; index < state.merchant.merchants.length; index++) {
    const ele = state.merchant.merchants[index];
    if (ele._id == id){
      dispatch({type: GET_MERCHANT, payload: ele})
      done = true
    }
  }
  if(!done){
    dispatch({type: GET_MERCHANT, payload: null})
  }
};

export const addMerchants = (data, history) => async dispatch => {
  const config = {headers: {'content-type': 'multipart/form-data'}};

  Axios.post(`/merchants/new`, data, config).then(result => history.push('/list-merchants'))
  .catch(error => error.response ? 
    dispatch({type: GET_ERRORS, payload: error.response.data}) : 
    dispatch({type: GET_ERRORS, payload: {path: 'no response', mssg: 'couldnt reach the server please try again later',}}));
}

export const updateMerchant = (_id, data, history) => async dispatch => {

  Axios.post(`/api/merchant/${_id}`, data)
    .then(result => {
      console.log(result.data)
      history.push('/list-merchants');
    })
    .catch(error => {
      error.response 
      ? dispatch({type: GET_ERRORS, payload: error.response.data})
      : dispatch({ type: GET_ERRORS, payload: {path: 'no response', msg: 'couldnt reach the server please try again later'}})
      alert(error.response ? error.response.data.message : error.message )
    })
};
