import React from 'react';
import moment from 'moment';
import Page from 'components/Page';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';

export const AccountDetail = ({
  _id,
  updatedAt,
  createdAt,
  merchant_name,
  cinema,
  purchased_date,
  merchant_email,
  balance,
  redeem_count
}) => {
  return (
    <Page>
      <Row>
        <Col xl={6} lg={6} md={6}>
          <ListGroup>
            <ListGroupItem>Merchant Name: {merchant_name}</ListGroupItem>
            <ListGroupItem>Merchant Email: {merchant_email}</ListGroupItem>
            <ListGroupItem>Balance: {balance}</ListGroupItem>
          </ListGroup>
        </Col>
        <Col xl={6} lg={6} md={6}>
          <ListGroup>
            <ListGroupItem>Redeem Count: {redeem_count} </ListGroupItem>
            <ListGroupItem>Cinema: {cinema}</ListGroupItem>
            <ListGroupItem>Purchased Date: {purchased_date} </ListGroupItem>
            <ListGroupItem>
              Last Transaction: {moment(updatedAt).format('MMMM Do YYYY, h:mm:ss a')}{' '}
            </ListGroupItem>
            <ListGroupItem>
              Date Added: {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}{' '}
            </ListGroupItem>
          </ListGroup>
        </Col>
      </Row>
    </Page>
  );
};

/**
 *      "gifts": [
        {
            "_id": "5ccf3798402c242128fb5dcf",
            "updatedAt": "2019-05-05T19:20:56.854Z",
            "createdAt": "2019-05-05T19:20:56.854Z",
            "movie": "Glass",
            "cinema": "5cc03d7231f58a2a608279b9",
            "date": "2019-05-14T7:14:38.000Z",
            "winners_name": "LORD LUGARD",
            "winners_email": "griffinc317@gmail.com",
            "winners_phone": "09072777130",
            "quantity": "3",
            "__v": 0,
            "revoked": false
 */
