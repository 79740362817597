import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import {Axios} from "../../redux/axios.request.link"
import serverConnection from '../../serverConnection';

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Submit",
      email: "",
      password: ""
    }
  }

  async componentDidMount() {
    try {
      let token = window.localStorage.getItem('token')
      console.log("got here")
      if(token){
        window.location.replace("/dashboard")
      this.props.children.props.history.push('/dashboard');
      }
    }catch(e){
      //Swal.fire({title: 'Error!', text: e.response ? e.response.data.message : e.message, icon: 'error', confirmButtonText: 'ok'})
      //window.localStorage.removeItem('token')
      //window.localStorage.removeItem('user')
      //this.props.children.props.history.push('/');
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
      try {
        if(!this.state.email){ alert("You have to provide your email"); return} 
        if(!this.state.password){ alert("You have to provide your password"); return}
        this.setState({buttonText: "Loading..."})
        let res =  await Axios.post("/api/thirdparty/login", {email: this.state.email, password: this.state.password})
        this.setState({buttonText: "Submit"})
        window.localStorage.setItem('token', res.data.token);
        window.localStorage.setItem('user', JSON.stringify(res.data.user));
        this.props.history.push('/dashboard');
      } catch (error) {
          this.setState({buttonText: "Submit"})
          console.log(error.response ? error.response.data.message : error.message )
          alert(error.response ? error.response.data.message : error.message)
      }

  };

  onChange = (e) => {
    this.setState({ cinemaChain: e.target.value });
    console.log('selected: ', e.target.value)
    this.setState({ searchString: '' });
    this.setState({ filtered: [] });
  }



  render() {
    const { children } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>

        <FormGroup>
          <Label for="email">UID</Label>
          <Input type="text" name="email" value={this.state.email} onChange={(e)=> this.setState({ email: e.target.value })}/>
        </FormGroup>

        <FormGroup>
          <Label for="password">Password</Label>
          <Input type="password" name="password" value={this.state.password} onChange={(e)=> this.setState({ password: e.target.value })}/>
        </FormGroup>

        <FormGroup check>
          <Label check>
            <Input type="checkbox" />{' '} Remember me
          </Label>
        </FormGroup>
        <hr />
        <Button size="lg" className="bg-gradient-theme-left border-0" block onClick={this.handleSubmit}>
          {this.state.buttonText}
        </Button>
        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default AuthForm;
