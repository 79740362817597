export default {
    filmHouseMoviesUrl: 'https://movies-apis.nairabox.net/api/cinemas/getMovies/93cb8143-6264-e811-80c3-0004ffb07dad',
    genesisMoviesUrl: 'https://genesis-test-sandbox.herokuapp.com/api/v1/films',
    kadaMoviesUrl: 'https://movies-apis.nairabox.net/api/cinemas/getMovies/da7063a6-431d-ea11-a601-0004ffb07870',

    nairaboxMoviesUrl: 'https://movies-apis.nairabox.net/v2/tickets?as=movies',
    nairaboxUrl: 'https://movies-apis.nairabox.net/v2',
    locationsUrl: 'https://movies-apis.nairabox.net/v2',
    showtimeUrl: 'https://movies-apis.nairabox.net/api'
    //https://movies-apis.nairabox.net/api/cinemas/getSiteShowtime/3f2f862b-2702-4ea3-4385-08d5c686e473
    //http://movies-apis.nairabox.net:3000/v2/tickets?as=cinemas
}