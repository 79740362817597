import { Content, Footer, Header, Sidebar } from 'components/Layout';
import React from 'react';
//import { useHistory } from "react-router-dom";
import {Axios} from "../../redux/axios.request.link"
import Swal from 'sweetalert2'

class MainLayout extends React.Component {
  static isSidebarOpen() {
    return document.querySelector('.cr-sidebar').classList.contains('cr-sidebar--open');
  }

  componentWillReceiveProps({ breakpoint }) {
    if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }
  }

  async componentDidMount() {
    //let history = useHistory();
    try {
      this.checkBreakpoint(this.props.breakpoint);
      let token = window.localStorage.getItem('token')
      if(!token){
      this.props.children.props.history.push('/');
      }
      let response = await Axios.get("/api/checktoken/thirdparties")

    }catch(e){
      Swal.fire({title: 'Error!', text: e.response ? e.response.data.message : e.message, icon: 'error', confirmButtonText: 'ok'})
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
      this.props.children.props.history.push('/');
    }
  }

  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (MainLayout.isSidebarOpen() && (this.props.breakpoint === 'xs' || this.props.breakpoint === 'sm' || this.props.breakpoint === 'md')) {
      this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return this.openSidebar('open');
    }
  }

  openSidebar(openOrClose) {
    if (openOrClose === 'open') {
      return document.querySelector('.cr-sidebar').classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  }

  render() {
    const { children } = this.props;
    return (
      <main className="cr-app bg-light">
        <Sidebar />
        <Content fluid onClick={this.handleContentClick}>
          <Header />
          {children}
          <Footer />
        </Content>
      </main>
    );
  }
}

export default MainLayout;
