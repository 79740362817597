import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Switch from "react-switch";
import {updateMovieTicketsStatus} from "../../../redux/actions/movies"
// import classnames from 'classnames';
// import PropTypes from 'prop-types';

class TicketItem extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    console.log("Got here...", checked)
    const {ticketDetails: {_id}, index} = this.props;
    this.props.updateMovieTicketsStatus(_id, index, checked? 1 : 0)
  }

  render() {
    const {ticketDetails: {_id, name, available, duration, genre}, history, index} = this.props;
    const edit = `/add-showtime/${_id}`;
    return (
      <React.Fragment>
        <tbody>
          <tr>
            <th scope="row">{index + 1}</th>
            <td>{name}</td>
            <td>{duration}</td>
            <td>{genre}</td>
            <td>
              <Switch onChange={this.handleChange} checked={available ? true : false} />
            </td>
            <td>
              <Button color="secondary" onClick={() => { history.push(edit);}} active>
                edit
              </Button>
            </td>
          </tr>
        </tbody>
      </React.Fragment>
    );
  }
}
const map_state_to_props = state => ({
  mt: state.movieTickets,
});

export default connect(map_state_to_props,{updateMovieTicketsStatus})(TicketItem);