import logo200Image from 'assets/img/logo/nairabox-icon.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import { FaGithub } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdPages,
  MdRadioButtonChecked,
  MdSend,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWeb,
  MdWidgets,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { Collapse, Nav, Navbar, NavItem, NavLink as BSNavLink} from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navMovies = [
  { to: '/create-movie', name: 'Create Movie', exact: false, Icon: MdAccountCircle},
  { to: '/list-movies', name: 'list movies', exact: false, Icon: MdViewCarousel},
  { to: '/cinema-linking', name: 'MX Cinema Linking', exact: false, Icon: MdWeb },
  { to: '/cinema-linking-jackrow', name: 'Jackrow Cinema Linking', exact: false, Icon: MdWeb },
];

const navMovieSubscriptions = [
  { to: '/list-subscriptions', name: 'List Subscribers', exact: false, Icon: MdAccountCircle}
];

const navTransactions = [
  {
    to: '/daily-transactions',
    name: 'Daily',
    exact: false,
    Icon: MdAccountCircle,
  },
  {
    to: '/weekly-transactions',
    name: 'Weekly',
    exact: false,
    Icon: MdViewCarousel,
  },
  {
    to: '/monthly-transactions',
    name: 'Monthly',
    exact: false,
    Icon: MdAccountCircle,
  },
  {
    to: '/range-transactions',
    name: 'Range',
    exact: false,
    Icon: MdAccountCircle,
  },
];

const navRedemptions = [
  {
    to: '/redeem-ticket',
    name: 'Redeem Ticket',
    exact: false,
    Icon: MdAccountCircle,
  },
  {
    to: '/redeemed-tickets',
    name: 'Redeemed Tickets',
    exact: false,
    Icon: MdViewCarousel,
  },
];

const navGifting = [
  {
    to: '/gift-ticket',
    name: 'Gift Ticket',
    exact: false,
    Icon: MdAccountCircle,
  },
  {
    to: '/gifted-tickets',
    name: 'Gifted Tickets',
    exact: false,
    Icon: MdViewCarousel,
  },
];

const navVouchers = [
  {
    to: '/create-voucher',
    name: 'Create Account',
    exact: false,
    Icon: MdAccountCircle,
  },
  {
    to: '/top_up',
    name: 'Credit Account',
    exact: false,
    Icon: MdViewCarousel,
  },
  {
    to: '/voucher-balance',
    name: 'Check Balance',
    exact: false,
    Icon: MdViewCarousel,
  },
  {
    to: '/wallet_list',
    name: 'Accounts',
    exact: false,
    Icon: MdViewCarousel,
  },
];

const navMerchants = [
  {
    to: '/create-merchant',
    name: 'Create Merchant',
    exact: false,
    Icon: MdAccountCircle,
  },
  {
    to: '/list-merchants',
    name: 'List Merchants',
    exact: false,
    Icon: MdViewCarousel,
  },
];

const navImages = [
  {
    to: '/home-banner',
    name: 'Home Banner',
    exact: false,
    Icon: MdAccountCircle,
  },
  {
    to: '/app-banner',
    name: 'App Banner',
    exact: false,
    Icon: MdViewCarousel,
  },
];

const navContents = [
  { to: '/typography', name: 'typography', exact: false, Icon: MdTextFields },
  { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
];

const pageContents = [
  { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
  {
    to: '/login-modal',
    name: 'login modal',
    exact: false,
    Icon: MdViewCarousel,
  },
];

const navItems = [
  { to: '/dashboard', name: 'dashboard', exact: true, Icon: MdDashboard },

];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: false,
    isOpenContents: false,
    isOpenMovies: false,
    isOpenMovieSubcriptions: false,
    isOpenTransactions: false,
    isOpenRedemptions: false,
    isOpenGifting: false,
    isOpenVouchers: false,
    isOpenMerchants: false,
    isOpenImages: false,
    isOpenPages: false,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img src={logo200Image} width="40" height="30" className="pr-2" alt=""/>
              <span className="text-white">Nairabox ThirdParty</span>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink id={`navItem-${name}-${index}`} className="text-uppercase" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}


            {/*

            <NavItem className={bem.e('nav-item')} onClick={this.handleClick('Movies')}>
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSend className={bem.e('nav-item-icon')} />
                  <span className="">Movies</span>
                </div>
                <MdKeyboardArrowDown className={bem.e('nav-item-icon')} style={{ padding: 0, transform: this.state.isOpenMovies ? 'rotate(0deg)' : 'rotate(-90deg)', transitionDuration: '0.3s', transitionProperty: 'transform' }} />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenMovies}>
              {navMovies.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-uppercase" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>



            <NavItem className={bem.e('nav-item')} onClick={this.handleClick('MovieSubcriptions')}>
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSend className={bem.e('nav-item-icon')} />
                  <span className="">Movie Subscription</span>
                </div>
                <MdKeyboardArrowDown className={bem.e('nav-item-icon')} style={{ padding: 0, transform: this.state.isOpenMovieSubcriptions ? 'rotate(0deg)' : 'rotate(-90deg)', transitionDuration: '0.3s', transitionProperty: 'transform' }} />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenMovieSubcriptions}>
              {navMovieSubscriptions.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-uppercase" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>


            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Transactions')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Transactions</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenTransactions
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenTransactions}>
              {navTransactions.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Redemptions')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Redemptions</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenRedemptions
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenRedemptions}>
              {navRedemptions.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>


            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Gifting')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Gifting</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenGifting
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenGifting}>
              {navGifting.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Vouchers')}
            >
            
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Wallet</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenVouchers
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenVouchers}>
              {navVouchers.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>


            <NavItem className={bem.e('nav-item')} onClick={this.handleClick('Merchants')}>
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Merchants</span>
                </div>
                <MdKeyboardArrowDown className={bem.e('nav-item-icon')} style={{padding: 0, transform: this.state.isOpenMerchants ? 'rotate(0deg)' : 'rotate(-90deg)', transitionDuration: '0.3s', transitionProperty: 'transform'}}/>
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenMerchants}>
              {navMerchants.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink id={`navItem-${name}-${index}`} className="text-uppercase" tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem className={bem.e('nav-item')} onClick={this.handleClick('Images')}>
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Dynamic Images</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenImages
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenImages}>
              {navImages.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Contents')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSend className={bem.e('nav-item-icon')} />
                  <span className="">Contents</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenContents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenContents}>
              {navContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Pages')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdPages className={bem.e('nav-item-icon')} />
                  <span className="">Pages</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPages
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPages}>
              {pageContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>


              */}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
