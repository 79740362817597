import axios from 'axios';
import { MOVIE_DASHBOARD_API_LOCAL } from '../axios.request.link';
import {
    REDEEM_LOADING,
    SET_REDEEM,
    GET_REDEEM,
  GET_ERRORS,
} from '../types';



export const redeemLoading = () => {
  return {
    type: REDEEM_LOADING,
  };
};

export const getRedeemed = _ => async dispatch => {
  dispatch(redeemLoading());
  // MAKME REQUEST
  axios
    .get(`${MOVIE_DASHBOARD_API_LOCAL}/redeem/redeemed`)
    .then(({ data }) =>
      dispatch({
        type: GET_REDEEM,
        payload: data.redeem,
      }),
    )
    .catch(error =>
      dispatch({
        type: GET_REDEEM,
        payload: null,
      }),
    );
};



export const redeemMoney = (data, history) => async dispatch => {
  axios
    .post(`${MOVIE_DASHBOARD_API_LOCAL}/redeem`, data)
    .then(result => history.push('/RedeemedTickets'))
    .catch(error =>
      error.response
        ? dispatch({
            type: GET_ERRORS,
            payload: error.response.data,
          })
        : dispatch({
            type: GET_ERRORS,
            payload: {
              path: 'no response',
              mssg: 'couldnt reach the server please try again later',
            },
          }),
    );
};

export const setRedeem = data => dispatch => {
  dispatch(redeemLoading());
  dispatch({
    type: SET_REDEEM,
    payload: data,
  });
};
