import Page from 'components/Page';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { redeemMoney, redeemLoading } from '../../redux/actions/redemptions';
import moment from 'moment';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { redeemState } from '../../initializers';

class RedeemTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...redeemState,
   
    };
  }


  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { redeemMoney, history } = this.props;

    const newStateObject = {
      ...this.state,
    };
   
    redeemMoney(newStateObject, history);
  };

  calenderonChange = date => this.setState({ date });

  render() {
    return (
      <Page
        title="Redeem Ticket"
        breadcrumbs={[{ name: 'Redeem Ticket', active: true }]}
      >
        <Row>
          <Col xl={12} lg={12} md={12}>
            <Card>
              <CardHeader>Redeem A Ticket</CardHeader>
              <CardBody>
                <Form>
                  {/* WINNERS NAME */}
                  <FormGroup row>
                    <Label for="email" sm={3}>
                     Email
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Enter Merchants Email"
                        onChange={this.onChange}
                      />
                    </Col>
                  </FormGroup>

                  {/* Ticket ID */}
                  <FormGroup row>
                    <Label for="ticketid" sm={3}>
                     Ticket ID
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="text"
                        name="ticketid"
                        placeholder="Enter A Ticket ID"
                        onChange={this.onChange}
                      />
                    </Col>
                  </FormGroup>

                    {/* Ticket ID */}
                    <FormGroup row>
                    <Label for="price" sm={3}>
                    Price
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="number"
                        name="price"
                        placeholder="Enter Ticket Price"
                        onChange={this.onChange}
                      />
                    </Col>
                  </FormGroup>

                  {/* BUTTON */}
                  <FormGroup check row>
                    <Col sm={{ size: 9, offset: 3 }}>
                      <Button onClick={this.onSubmit}>redeem Ticket</Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

const map_state_to_props = state => ({
  redeem: state.redeem,
});

export default connect(
  map_state_to_props,
  {redeemMoney},
)(withRouter(RedeemTicket));
