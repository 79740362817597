import Page from 'components/Page';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { } from '../../redux/actions/movies';
import { getCinemas } from '../../redux/actions/cinemas';
import { addWallet } from '../../redux/actions/vouchers';
import moment from 'moment';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { createWalletState } from '../../initializers';
import { mongodateFormatter } from '../../utils/gendate';
import DatePicker from 'react-date-picker';
import 'react-datepicker/dist/react-datepicker.css';


class CreateVoucher extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...createWalletState,
      cinema_location: [],
    };
  }

  componentDidMount() {
    const {  getCinemas } = this.props;
    getCinemas();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cinema) {
      const { loading, cinemas } = nextProps.cinema;
      if (loading === false && cinemas !== null) {
        let selectionArray = ['please select a cinema location'];
        cinemas.forEach(({ name, _id }) => {
          selectionArray.push({ name, _id });
        });
        this.setState({ cinema_location: selectionArray });
      }
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { addWallet, history } = this.props;

    const newStateObject = {
      ...this.state,
    };
    delete newStateObject['cinema_location'];
    // format date + time
    if (newStateObject['date'] !== '') {
      newStateObject['date'] = mongodateFormatter(
        newStateObject.date,
        newStateObject.time,
      );
    }

    this.state.cinema_location.forEach(({ name, _id }) => {
      if (name === newStateObject['cinema']) {
        newStateObject['cinema'] = _id;
      }
    });
    // delete time
    delete newStateObject['time'];
    addWallet(newStateObject, history);
  };

  calenderonChange = date => this.setState({ date });

  render() {
    return (
      <Page
        title="Account"
        breadcrumbs={[{ name: 'Create Account', active: true }]}
      >
        <Row>
          <Col xl={12} lg={12} md={12}>
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                <Form>
                   {/* MERCHANT NAME */}
                   <FormGroup row>
                    <Label for="merchant_name" sm={3}>
                      Merchant Name
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="text"
                        name="merchant_name"
                        placeholder="Merchant Name"
                        onChange={this.onChange}
                      />
                    </Col>
                  </FormGroup>
                  
                  {/* MERCHANT EMAIL */}
                  <FormGroup row>
                    <Label for="merchant_email" sm={3}>
                      Merchant Email
                    </Label>
                    <Col sm={6}>
                    <Input
                        type="email"
                        name="merchant_email"
                        placeholder="Enter Merchant Email"
                        onChange={this.onChange}
                      />
                    </Col>
                  </FormGroup>

                  {/* SELECT CINEMA */}
                  <FormGroup row>
                    <Label for="cinema" sm={3}>
                      Select Cinema
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="select"
                        name="cinema"
                        onChange={this.onChange}
                      >
                        {this.state.cinema_location.map((obj, i) => {
                          return <option key={i}>{obj.name}</option>;
                        })}
                      </Input>
                    </Col>
                  </FormGroup>

                  {/* SELECT DATE Time */}
                  <FormGroup row className="align-items-center">
                    <Label for="purchased_date" sm={3}>
                      Actual Purchased Date
                    </Label>
                    <Col sm={1}>Date:</Col>
                    <Col sm={2}>
                      <DatePicker
                        name="purchased_date"
                        onChange={this.calenderonChange}
                        value={this.state.date}
                      />
                    </Col>
                  </FormGroup>

                  {/* BUTTON */}
                  <FormGroup check row>
                    <Col sm={{ size: 9, offset: 3 }}>
                      <Button onClick={this.onSubmit}>CREATE ACCOUNT</Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

const map_state_to_props = state => ({
  gift: state.gift,
  mt: state.movieTickets,
  cinema: state.cinemas,
});

export default connect(
  map_state_to_props,
  {  getCinemas, addWallet },
)(withRouter(CreateVoucher));


