import Page from 'components/Page';
import axios from 'axios';
import Swal from 'sweetalert2'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, Col, Table, Row, FormGroup, Input} from 'reactstrap';
import SubItem from '../../components/common/List/SubscriptionItem';
// MODAL CREATE DYNAMIC MODALS
import Dialogue from '../../components/common/Modal/a.index';
import DialogueTitle from '../../components/common/Modal/Title';
import DialogueContent from '../../components/common/Modal/Content';
import DialogueAction from '../../components/common/Modal/Action';
import { SubscriptionDetails } from './SubscriptionDetails';
import LoadSpinner from '../../components/common/spinner';
import spin from '../../utils/spin.gif';

import { getMerchants, getMerchant } from '../../redux/actions/merchant';

class ListMerchants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      searching: true,
      sub: {},
      subs: [],
      subscriptionDetails: {
        _id: "",
        user: "",
        details: {},
        subscriptionPlan: "",
        subscriptionType: "regular",
        expires: "",
        status: "active",
        active: true,
        movies: [
            {
                date: "2021-03-05T19:28:35.204Z",
                showtime: "60417ba18ead0e2c148b4650"
            }
        ],
      }
    };

    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { getMerchants } = this.props;
    this.getSubs();
  }

  async getSubs(user, active) {
      let url = "https://api.nairaboxoffice.com/admin"
      //let url = "http://localhost:9000/admin"
      //url = user ? url + `user=${user}&` : url
      //url = active ? url + `active=true` : url + `active=true`
      try {
        let res = await fetch(url, {method: 'GET', headers: {'Content-Type': 'application/json', 'requester-x-id': 'nairaboxadmin'}});

        let data = await res.json();
        console.log(data)
        this.setState({subs: data.payload})
      } catch (e) {
          console.log(e.response ? e.response.data : e.message)
        Swal.fire({title: 'Error!', text: e.response ? e.response.data : e.message, icon: 'error', confirmButtonText: 'ok'})
      }

  }

  toggle(sub) {
    if(!sub) return
    sub.details = {}
    this.setState({subscriptionDetails: sub})
    this.setState({modal: true})
    fetch(`https://food-admin.nairabox.com/api/partner/users/${sub.user}/view`, {method: "GET", headers: {Authorization: `Bearer $2y$10$nP1I94Z8mm/FpPsxmfoV.uxd7J048qooCgaG/hQMrn.BzsqfJszpy`}})
    .then(async (res) => {
      let data = await res.json()
      sub.details =  {firstName: data.firstname, lastName: data.lastname, email: data.email, phone: data.phone}
      this.setState({subscriptionDetails: sub})
      console.log(data) 
    })
    .catch((e) => console.log(e.response))
    
    //this.setState(prevState => ({modal: !prevState.modal}));
  }

  close() {
    this.setState({modal: false});
  }

  onChange = async e => {
    let search = e.target.value;
    let {merch: { loading }, getMerchants} = this.props;
    if (!loading && search){
      getMerchants(search)
    }
  };

  render() {
    const {merch: { loading, merchants, merchant }} = this.props;
    let listMerchantItems;

    const externalCloseBtn = (
      <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.close}>
        &times;
      </button>
    );

    let dialogueTitle;
    let dialogueContent;
    let merchantDialog;
    let dialogueAction;

      // populates data table loaidng a merchant??
      dialogueTitle = (
        <DialogueTitle simpleContext={ this.state.subscriptionDetails.details.firstName ? '' : "Subscription for " + this.state.subscriptionDetails.details.firstName}/>
      );

      dialogueContent = (
        <DialogueContent contextComponent={ this.state.subscriptionDetails._id === "" ? ( <LoadSpinner src={spin} />) : ( <SubscriptionDetails sub={this.state.subscriptionDetails} />)}/>
      );

      dialogueAction = (
        <DialogueAction contextComponent={<Button color="secondary" onClick={this.close}> Cancel </Button>}/>
      );

      merchantDialog = (
        <Dialogue DialogTitle={dialogueTitle} DialogContent={dialogueContent} DialogActions={dialogueAction} isOpen={this.state.modal} toggle={this.toggle} externalCloseBtn={externalCloseBtn}/>
      );
   

    this.state.subs.length ? (listMerchantItems = this.state.subs.map((merch, i) => (
          <SubItem key={merch._id} toggle={this.toggle} history={this.props.history} index={i} subDetails={merch} />
    )))
        :     
    (listMerchantItems = <h4>No Subscribers Where Found!</h4>)

    return (
      <React.Fragment>
        {merchantDialog}
        {/* <DemoModal modal={this.state.modal} toggle={this.toggle} /> */}
        <Page title="List Merchants" breadcrumbs={[{ name: 'List Merchants', active: true }]}>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardHeader>Search for Subscribers</CardHeader>
                <CardBody>
                <Row>
                  <Col xl={12} lg={12} md={12}>
                  <FormGroup>
                      <Input type="text" name="search" placeholder="Name/email/phone" onChange={this.onChange}/>
                  </FormGroup>
                  </Col>
                </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardHeader>List Of All Subscibers</CardHeader>
                <CardBody>
                  <Col>
                    <Card body>
                      <Table striped={true} id="listmerchantstable">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>User</th>
                            <th>Subscription Type</th>
                            <th>Expires</th>
                            <th>Active</th>
                            <th>View</th>
                          </tr>
                        </thead>
                        {listMerchantItems}
                      </Table>
                    </Card>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Page>
      </React.Fragment>
    );
  }
}

const map_state_to_props = state => ({
  merch: state.merchant,
});

export default connect(map_state_to_props, { getMerchants, getMerchant } )(withRouter(ListMerchants));
