import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from 'components/Page';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';

function Greeting(props) {
    let key = props.k;
    let value = props.v;
    if (key == "active") {
      return <ListGroupItem>active: {value ? "True" : "False"}</ListGroupItem>;
    }else if(key == "details"){
        return <ListGroupItem> UserDetails
                    <p style={{marginTop: "10px"}}>
                    {value ? Object.keys(value).map((k, i) => (
                            <div style={{width: "100%"}}>{k}: {value[k]} </div>
                        )) : ("[]")}
                    </p>
        </ListGroupItem>;
    }else if(key == "expires"){
        return <ListGroupItem>{key}: {(new Date(value)).toUTCString()}</ListGroupItem>;
    }else if(key == "createdAt"){
        return <ListGroupItem>{key}: {(new Date(value)).toUTCString()}</ListGroupItem>;
    }else if(key == "updatedAt"){
        return <ListGroupItem>{key}: {(new Date(value)).toUTCString()}</ListGroupItem>;
    }else if(key == "__v"){
        return <ListGroupItem>Updates: {value}</ListGroupItem>;
    }else if(key == "movies"){
        return <ListGroupItem> Movies
               {value ? value.map((k, i) => (
                    <p>Date: {(new Date(k.date)).toUTCString()} Showtime: {k.showtime} </p>
                )) : ("Movies: []")}
        </ListGroupItem>;
    }else{
        return <ListGroupItem>{key}: {typeof value === 'object' && value !== null ? "" : value}</ListGroupItem>;
    }
  }

export const SubscriptionDetails = ({sub}) => {
    //let active = (<ListGroupItem>active: {sub[key]? "True" : "False"}</ListGroupItem>)
  return (
    <Page>

          <ListGroup>
                {Object.keys(sub).map((key, i) => (
                    <Greeting k={key} v={sub[key]} />
                ))}

          </ListGroup>
    </Page>
  );
};
