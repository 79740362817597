import Page from 'components/Page';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Table, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import AccountItem from '../../components/common/List/AccountItem';
import { AccountDetail } from './AccountDetails';
// MODAL CREATE DYNAMIC MODALS
import Dialogue from '../../components/common/Modal/a.index';
import DialogueTitle from '../../components/common/Modal/Title';
import DialogueContent from '../../components/common/Modal/Content';
import DialogueAction from '../../components/common/Modal/Action';
// SPINNER IMAGES
import LoadSpinner from '../../components/common/spinner';
import spin from '../../utils/spin2.gif';
import { getWallets, getWallet, setWallet } from '../../redux/actions/vouchers';

class ListWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(index) {
    const {
      accounts: { accounts },
      setWallet,
    } = this.props;
    let data = accounts;

    if (typeof index === 'number') {
      setWallet(data[index]);
    }
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  close() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  componentDidMount() {
    const { getWallets } = this.props;
    getWallets();
  }


  render() {
    const {
      accounts: { loading, accounts, detail },
    } = this.props;
    let listAccountItems;

    const externalCloseBtn = (
      <button
        className="close"
        style={{ position: 'absolute', top: '15px', right: '15px' }}
        onClick={this.close}
      >
        &times;
      </button>
    );

    let dialogueTitle;
    let dialogueContent;
    let dialogueAction;
    let accountDialog;

    if (accounts && !loading) {
      // populates data table loaidng a merchant??
      dialogueTitle = (
        <DialogueTitle
          simpleContext={
            detail === null || detail === undefined ? '' : detail.winners_name
          }
        />
      );
      dialogueContent = (
        <DialogueContent
          contextComponent={
            detail === null ? (
              <LoadSpinner src={spin} />
            ) : (
              <accountDetail {...detail} />
            )
          }
        />
      );
      dialogueAction = (
        <DialogueAction
          contextComponent={
            <Button color="secondary" onClick={this.close}>
              Cancel
            </Button>
          }
        />
      );
      accountDialog = (
        <Dialogue
          DialogTitle={dialogueTitle}
          DialogContent={dialogueContent}
          // DialogActions={dialogueAction}
          isOpen={this.state.modal}
          toggle={this.toggle}
          // className={}
          externalCloseBtn={externalCloseBtn}
        />
      );
    } else {
      accountDialog = <React.Fragment />;
    }

    loading === true && accounts == null
      ? (listAccountItems = <React.Fragment />)
      : loading === false && accounts == null
      ? (listAccountItems = <React.Fragment />)
      : accounts.length > 0
      ? (listAccountItems = accounts.map((account, i) => (
          <AccountItem
            key={account._id}
            toggle={this.toggle}
            index={i}
            accountDetails={AccountDetail}
          />
        )))
      : (listAccountItems = <h4>No Movie Tickets Where Found!</h4>);

    return (
      <React.Fragment>
        {accountDialog}
        <Page
          title="Accounts"
          breadcrumbs={[{ name: 'Account  Info', active: true }]}
        >
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardHeader>Account Information</CardHeader>
                <CardBody>
                  <Col>
                    <Card body>
                      <Table striped={true} id="ListWallettable">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Winner's Name</th>
                            <th>Winner's Email</th>
                            <th>Movie Title</th>
                            <th>Date/Time</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        {listAccountItems}
                      </Table>
                    </Card>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Page>
      </React.Fragment>
    );
  }
}

const map_state_to_props = state => ({
  accounts: state.account,
});

export default connect(
  map_state_to_props,
  { getWallets, setWallet },
)(ListWallet);
