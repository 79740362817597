import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Switch from "react-switch";
import {updateMovieTicketsStatus} from "../../../redux/actions/movies"
// import classnames from 'classnames';
// import PropTypes from 'prop-types';

class TicketItem extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    console.log("Got here...", checked)
    //const {ticketDetails: {_id}, index} = this.props;
    //this.props.updateMovieTicketsStatus(_id, index, checked? 1 : 0)
  }

  render() {
    const {subDetails: {_id, subscriptionType, expires, user, active}, history, index, toggle} = this.props;
    const edit = `/view-subscription/${_id}`;
    return (
      <React.Fragment>
        <tbody>
          <tr>
            <th scope="row">{index + 1}</th>
            <td>{user}</td>
            <td>{subscriptionType}</td>
            <td>{(new Date(expires)).toUTCString()}</td>

            <td>
              <Switch onChange={this.handleChange} checked={active ? true : false} />
            </td>
            <td>
              <Button color="secondary" onClick={() => { toggle(this.props.subDetails); console.log("here")}} active>
                view
              </Button>
            </td>
          </tr>
        </tbody>
      </React.Fragment>
    );
  }
}
const map_state_to_props = state => ({mt: state.movieTickets});

export default connect(map_state_to_props,{updateMovieTicketsStatus})(TicketItem);