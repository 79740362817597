import Page from 'components/Page';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, FormText, Input, Label, Row} from 'reactstrap';
import Swal from 'sweetalert2'
import { addMovieTicket } from '../../redux/actions/movies';
import { MOVIE_DASHBOARD_API_LOCAL, Axios } from '../../redux/axios.request.link';

let checkImage3 = function(src){
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = () => { 
      resolve(img) 
    }
    img.onerror = reject
    img.src = src
  })
}

let checkImage = async src => {
  let blob = await checkImage2(src) 
  let ret = await checkImage3(blob)
  return ret
} 

const checkImage2 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

class CreateMovie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      summary: '', //description
      ageRestriction: '',
      genre: '',
      director: '',
      youtube_trailer: '',
      duration: '', // runnig time
      starring: '',
      available: 0, // done

      artwork: '', // synopis image
      cardImage: '',
      bannerImage: '',
      featuredImage: '',

      active: false,
    };
  }

  pictureDictionary = {
    artwork: true, 
    cardImage: true,
    bannerImage: true,
    featuredImage: true,
  };

  onChange = e => {
    //this.setState({[e.target.name]: e.target.value});
    this.pictureDictionary[e.target.name] ? this.setState({[e.target.name]: [e.target.files[0]]}) : this.setState({[e.target.name]: e.target.value});
  };

  onSubmit = async e => {
    e.preventDefault();
    this.setState({active: true})
    let errs = ""
    const mutable_state = {...this.state};
    const { addMovieTicket, history } = this.props;

    delete mutable_state['artwork'];
    delete mutable_state['bannerImage'];
    delete mutable_state['cardImage'];
    delete mutable_state['featuredImage'];


    const fd = new FormData();

    Object.keys(mutable_state).forEach(k => { fd.append(k, mutable_state[k]); })

    if(!this.state.name){
      errs = errs + "Movie title is required. "
    }
    if(!this.state.summary){
      errs = errs + "Movie description/summary is required. "
    }
    if(!this.state.ageRestriction){
      errs = errs + "Movie Age restriction is required. "
    }
    if(!this.state.genre){
      errs = errs + "Movie genre is required. "
    }

    try {

      if (this.state.artwork != '') {
        console.log("wwwww", this.state.artwork[0])
        let img = await checkImage(this.state.artwork[0])
        if(img.width != 258 || img.height != 382){
          errs = errs + "Slider image must be of the dimension width 258 and height 382. "
        }else{
          fd.append('artwork', this.state.artwork[0], this.state.artwork[0].name);
        }
      }else{
          errs = errs + "Artwork image is required. "
      }
      if (this.state.cardImage != '') {
        let img = await checkImage(this.state.cardImage[0])
        if(img.width != 175 || img.height != 313){
          errs = errs + "cardImage image must be of the dimension height 313 and width 175. "
        }else{
          fd.append('cardImage', this.state.cardImage[0], this.state.cardImage[0].name);
        }
      }else{
          errs = errs + "cardImage image is required. "
      }
      if (this.state.bannerImage != '') {
        let img = await checkImage(this.state.bannerImage[0])
        if(img.width != 1440 || img.height != 321){
          errs = errs + "bannerImage image must be of the dimension height 321 and width 1440. "
        }else{
          fd.append('bannerImage', this.state.bannerImage[0], this.state.bannerImage[0].name);
        }
      }else{
          errs = errs + "bannerImage image is required. "
      }
      if (this.state.featuredImage != '') {
        let img = await checkImage(this.state.featuredImage[0])
        console.log("tttttt", img.width, img.height)
        if(img.width != 1400 || img.height != 772){
          errs = errs + "cardImage image must be of the dimension height 772 and width 1400. "
        }else{
          fd.append('featuredImage', this.state.featuredImage[0], this.state.featuredImage[0].name);
        }
      }else{
          errs = errs + "featuredImage image is required. "
      }
    if(errs == ""){
      const config = {headers: {'content-type': 'multipart/form-data'}};
      let r = await Axios.post(`/api/tickets/new`, fd, config)
      history.push('/dashboard');
    }else{
      this.setState({active: false})
      Swal.fire({ title: 'Error!', text: errs, icon: 'error', confirmButtonText: 'ok' })
    }
    } catch (error) {
      this.setState({active: false})
      console.log(error)
      Swal.fire({ title: 'Error!', text: error.response ? (error.response.data.message ? error.response.data.message : error.response.data) : error.message, icon: 'error', confirmButtonText: 'ok' })
    }
  };

  render() {
    let image_render;

    image_render = (
          <React.Fragment>
            {/* CARD IMAGE */}
            <FormGroup row>
              <Label for="cardImage" sm={3}> Card Image </Label>
              <Col sm={9}>
                <Input type="file" name="cardImage" onChange={this.onChange} />
                <FormText color="muted">width: 175px by height: 313px</FormText>
              </Col>
            </FormGroup>

            {/* ARTWOK*/}
            <FormGroup row>
              <Label for="artwork" sm={3}> Slider Image </Label>
              <Col sm={9}>
                <Input type="file" name="artwork" onChange={this.onChange} />
                <FormText color="muted">width: 258px by height: 382px</FormText>
              </Col>
            </FormGroup>

            {/* BANNER IMAGE */}
            <FormGroup row>
              <Label for="bannerImage" sm={3}> Banner Image </Label>
              <Col sm={9}>
                <Input type="file" name="bannerImage" onChange={this.onChange} />
                <FormText color="muted">width: 1440px by width: 321px</FormText>
              </Col>
            </FormGroup>

            {/* SYNOPSIS IMAGE */}
            <FormGroup row>
              <Label for="featuredImage" sm={3}> Featured Image </Label>
              <Col sm={9}>
                <Input type="file" name="featuredImage" onChange={this.onChange} />
                <FormText color="muted">width: 1400px by height: 772px</FormText>
              </Col>
            </FormGroup>
          </React.Fragment>
        );

    return (
      <Page title="Create Movies" breadcrumbs={[{ name: 'Create Movie', active: true }]}>
        <Row>
          <Col xl={12} lg={12} md={12}>
            <Card>
              <CardHeader>Create a new movie</CardHeader>
              <CardBody>
                <Form>
                  {/* MOVIE TICKET NAME */}
                  <FormGroup row>
                    <Label for="name" sm={3}> Movie Title </Label>
                    <Col sm={9}>
                      <Input type="text" name="name" placeholder="title of movie" onChange={this.onChange}/>
                    </Col>
                  </FormGroup>
                  {/* SYNPOSIS || SUMMARY */}
                  <FormGroup row>
                    <Label for="summary" sm={3}> Description/Synopsis </Label>
                    <Col sm={9}>
                      <Input type="textarea" name="summary" onChange={this.onChange}/>
                    </Col>
                  </FormGroup>

                  {/* AGE RESTRICTION */}
                  <FormGroup row>
                    <Label for="ageRestriction" sm={3}> Age Restriction </Label>
                    <Col sm={9}>
                      <Input type="text" name="ageRestriction" placeholder="age restriction" onChange={this.onChange}/>
                    </Col>
                  </FormGroup>

                  {/* GENRE */}
                  <FormGroup row>
                    <Label for="genre" sm={3}>
                      Genre
                    </Label>
                    <Col sm={9}>
                      <Input type="text" name="genre" placeholder="Genre - separate multiple genres by comma" onChange={this.onChange} />
                    </Col>
                  </FormGroup>

                  {/* DIRECTOR */}
                  <FormGroup row>
                    <Label for="director" sm={3}> Director </Label>
                    <Col sm={9}>
                      <Input type="text" name="director" placeholder="movie director" onChange={this.onChange}/>
                    </Col>
                  </FormGroup>

                  {/* YOUTUBE TRAILER */}
                  <FormGroup row>
                    <Label for="youtube_trailer" sm={3}> Youtube Trailer ID </Label>
                    <Col sm={9}>
                      <Input type="text" name="youtube_trailer" placeholder="Trailer ID" onChange={this.onChange}/>
                    </Col>
                  </FormGroup>

                  {/* RUNNING TIME */}
                  <FormGroup row>
                    <Label for="duration" sm={3}> Running Time </Label>
                    <Col sm={9}>
                      <Input type="text" name="duration" placeholder="duration of movie" onChange={this.onChange}/>
                    </Col>
                  </FormGroup>

                  {image_render}


                  {/* STARRING */}
                  <FormGroup row>
                    <Label for="starring" sm={3}> Starring </Label>
                    <Col sm={9}>
                      <Input type="text" name="starring" placeholder="starring" />
                    </Col>
                  </FormGroup>

                  {/*AVAILABLE|| CHECKBOX */}
                  <FormGroup row>
                    <Label for="available" sm={3}> Available ? </Label>
                    <Col sm={{ size: 9 }}>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" name="available" id="available"
                            onClick={() => {
                              this.setState(previousState => ({
                                available: previousState.available == 1 ? 0 : 1,
                              }));
                            }}
                          />{' '}
                          {this.state.available == 1 ? 'Yes' : 'No'}
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                  {/* BUTTON || CREATE MOVIE  */}
                  <FormGroup check row>
                    <Col sm={{ size: 9, offset: 3 }}>
                      <Button active={this.state.active} onClick={this.onSubmit}>Create Movie</Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

const map_state_to_props = state => ({
  movie: state.movieTickets,
});

export default connect(
  map_state_to_props,
  { addMovieTicket },
)(withRouter(CreateMovie));
