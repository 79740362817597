import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

// import classnames from 'classnames';
// import PropTypes from 'prop-types';

export default class Merchant extends Component {
  render() {
    const {ticketDetails: {_id, customerName, customerEmail, customerPhone, purchaseID, showtimeID, movieTitle }, index, toggle, history} = this.props;
    let purchasedDate = ""
    if (purchaseID && purchaseID.purchasedDate) {
      let nd = new Date(purchaseID.purchasedDate)
      purchasedDate = nd.toString()
    }
    let mongodate = ""
    if (showtimeID && showtimeID.mongodate) {
      let nd = new Date(showtimeID.mongodate)
      mongodate = nd.toString()
    }

    return (
      <React.Fragment>


          <tr>
          <td><Button color="secondary" onClick={() => toggle(_id)} active>details</Button></td>
            <td>{customerName}</td>
            <td>{customerEmail}</td>
            <td>{customerPhone}</td>
            <td>{purchaseID ? purchaseID.validity : ""}</td>
            <td>{movieTitle}</td>
            <td>{showtimeID ? mongodate : ""}</td>
            <td>{purchaseID ? purchasedDate : ""}</td>


          </tr>
      </React.Fragment>
    );
  }
}
