import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from 'components/Page';
import { MOVIE_DASHBOARD_API_LOCAL, Axios } from '../../redux/axios.request.link';
import { Col, ListGroup, ListGroupItem, Row, Button } from 'reactstrap';
import Swal from 'sweetalert2'

let issueTicket = async (id) => {
  try{
    await Axios.get(`/api/merchants/tickets/issue/${id}`)
    Swal.fire({title: 'success!', text: "Ticket successfully issued", icon: 'success', confirmButtonText: 'ok'})
  }catch(e){
    Swal.fire({title: 'Error!', text: e.response ? e.response.data.message : e.message, icon: 'error', confirmButtonText: 'ok'})
  }
}

export const MerchantDetails = ({_id, customerName, customerEmail, customerPhone, purchaseID, showtimeID, movieTitle, ticketCount, amount, reference}) => {
  let purchasedDate = ""
  if (purchaseID && purchaseID.purchasedDate) {
    let nd = new Date(purchaseID.purchasedDate)
    purchasedDate = nd.toString()
  }
  let mongodate = ""
  if (showtimeID && showtimeID.mongodate) {
    let nd = new Date(showtimeID.mongodate)
    mongodate = nd.toString()
  }

  let ticketTypes = ""
  if (showtimeID && showtimeID.ticketTypes) {
    if (purchaseID && purchaseID.ticketTypes){
      for (let i = 0; i < purchaseID.ticketTypes.length; i++) {
        const ele = purchaseID.ticketTypes[i];
        let ticket =  showtimeID.ticketTypes.find(el => el.id == ele.ticketTypeId);
        if (ticket){
          ticketTypes = ticketTypes + `|  ${ticket.description} : ${ele.quantity}` 
        }
      }
    }
  }

  return (
    <Page>
          <ListGroup>
            <ListGroupItem>Email: {customerEmail}</ListGroupItem>
            <ListGroupItem>Phone: {customerPhone}</ListGroupItem>
            <ListGroupItem>BookingID: {purchaseID ? purchaseID.bookingId : ""}</ListGroupItem>
            <ListGroupItem>Movie Title: {movieTitle}</ListGroupItem>
            <ListGroupItem>Ticket Types: {ticketTypes}</ListGroupItem>
            <ListGroupItem>Ticket Count: {ticketCount}</ListGroupItem>
            <ListGroupItem>Amount Paid: {amount}</ListGroupItem>
            <ListGroupItem>Showing time: {showtimeID ? mongodate : ""}</ListGroupItem>
            <ListGroupItem>Purchased date: {purchaseID ? purchasedDate : ""}</ListGroupItem>
            <ListGroupItem>Reference: {reference ? reference : ""}</ListGroupItem>

            <ListGroupItem>Validity: {purchaseID ? purchaseID.validity : ""}</ListGroupItem>
            <ListGroupItem>IssueDate: {purchaseID ? purchaseID.issueDate: ""}</ListGroupItem>
          </ListGroup>
    </Page>
  );
};
